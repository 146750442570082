import gql from 'graphql-tag';

const PARTNER_FIELDS = `
  domains
  id
  name
  webAnalytics {
    id
    options
    type
  }
  webOptions
  webOptionFaviconUrl
  webOptionLogoUrl
  webOptionLogoBackgroundWhiteUrl
  webOptionMetaOgImageUrl
  claimSettings {
    notificationEmails
    notificationTelegramChatId
    isRequiredPhoneVerification
  }
  mappingSettings {
    cianJkIds
    cianOwnerIds
    cianSellerIds
    isDeveloper
  }
  parentId
  inheritedFields
  children {
    domains
    id
    name
    webAnalytics {
      id
      options
      type
    }
    webOptions
    webOptionFaviconUrl
    webOptionLogoUrl
    webOptionLogoBackgroundWhiteUrl
    webOptionMetaOgImageUrl
    claimSettings {
      notificationEmails
      notificationTelegramChatId
      isRequiredPhoneVerification
    }
    mappingSettings {
      cianJkIds
      cianOwnerIds
      cianSellerIds
      isDeveloper
    }
    parentId
    inheritedFields
  }
`;

export const query_partner = gql`
  query query_partner(
    $id: ID!
  ) {
    partner(
      id: $id,
    ) {
      ${PARTNER_FIELDS}
    }
  }
`;

export const query_partner_domains = gql`
  query query_partner(
    $id: ID!
  ) {
    partner(
      id: $id,
    ) {
      domains
      id
      name
    }
  }
`;

export const mutation_partner_create = gql`
  mutation mutation_partner_create(
    $name: String!,
    $domains: [String!]!,
    $web_options: JSON,
    $web_analytics: [InternalAdmin__Partner__WebAnalyticInputCreate!],
    $web_option_favicon: Upload,
    $web_option_logo: Upload,
    $web_option_logo_background_white: Upload,
    $web_option_meta_og_image: Upload,
    $claim_settings: InternalAdmin__Partner__ClaimSettingsInputCreate,
    $mapping_settings: InternalAdmin__Partner__MappingSettingsInputCreate,
    $parent_id: ID,
    $inherited_fields: [String!]
  ) {
    partnerCreate(
      input: {
        name: $name,
        domains: $domains,
        webOptions: $web_options,
        webAnalytics: $web_analytics,
        webOptionFavicon: $web_option_favicon,
        webOptionLogo: $web_option_logo,
        webOptionLogoBackgroundWhite: $web_option_logo_background_white,
        webOptionMetaOgImage: $web_option_meta_og_image,
        claimSettings: $claim_settings,
        mappingSettings: $mapping_settings,
        parentId: $parent_id,
        inheritedFields: $inherited_fields
      }
    ) {
      partner {
        ${PARTNER_FIELDS}
      }
      status
    }
  }
`;

export const mutation_partner_update = gql`
  mutation mutation_partner_update(
    $id: ID!,
    $name: String!,
    $domains: [String!]!,
    $web_options: JSON,
    $web_analytics: [InternalAdmin__Partner__WebAnalyticInputUpdate!],
    $is_web_option_favicon_remove: Boolean,
    $is_web_option_logo_remove: Boolean,
    $is_web_option_logo_background_white_remove: Boolean,
    $is_web_option_meta_og_image_remove: Boolean,
    $web_option_favicon: Upload,
    $web_option_logo: Upload,
    $web_option_logo_background_white: Upload,
    $web_option_meta_og_image: Upload,
    $claim_settings: InternalAdmin__Partner__ClaimSettingsInputUpdate,
    $mapping_settings: InternalAdmin__Partner__MappingSettingsInputUpdate,
    $parent_id: ID,
    $inherited_fields: [String!]
  ) {
    partnerUpdate(
      input: {
        id: $id,
        name: $name,
        domains: $domains,
        webOptions: $web_options,
        webAnalytics: $web_analytics,
        isWebOptionFaviconRemove: $is_web_option_favicon_remove,
        isWebOptionLogoRemove: $is_web_option_logo_remove,
        isWebOptionLogoBackgroundWhiteRemove: $is_web_option_logo_background_white_remove,
        isWebOptionMetaOgImageRemove: $is_web_option_meta_og_image_remove,
        webOptionFavicon: $web_option_favicon,
        webOptionLogo: $web_option_logo,
        webOptionLogoBackgroundWhite: $web_option_logo_background_white,
        webOptionMetaOgImage: $web_option_meta_og_image,
        claimSettings: $claim_settings,
        mappingSettings: $mapping_settings,
        parentId: $parent_id,
        inheritedFields: $inherited_fields
      }
    ) {
      partner {
        ${PARTNER_FIELDS}
      }
      status
    }
  }
`;
