<template>
  <div v-bind:class="formGroupClassName" v-if="property">
    <label v-if="formComponentLabelOuter">
      <span v-html="property.label.title"></span>
      <inherit-val v-if="canBeInherited" v-bind:name="name"></inherit-val>
    </label>
    <div class="filters-set-img-preview" v-if="property.hasImgPreview && value">
      <img v-bind:src="value" v-bind:alt="property.label.title">
    </div>
    <form-component v-bind:formComponent="property.formComponent"
                    v-bind:isDisabled="formComponentIsDisabled"
                    v-bind:field="{
                      value: content
                    }"
                    v-on:input="handleInput"
                    v-model="content"
                    v-bind:label="formComponentLabelInner"
                    v-bind:name="formComponentName"
                    v-bind:options="property.options"
                    v-bind:canBeInherited="canBeInherited"></form-component>
    <div class="text-muted small pt-1" v-if="property.note" v-html="property.note"></div>
  </div>
</template>

<script>
import FormComponent from '@/components/form/FormComponent.vue';
import InheritVal from '@/components/partners/InheritVal.vue';
import { mapGetters } from 'vuex';

export default {
  props: ['name', 'value', 'customName'],
  components: {
    FormComponent,
    InheritVal
  },
  data() {
    return {
      property: null,
      content: this.value
    };
  },
  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
      parentId: 'partners/getParentId'
    }),
    isCheckbox() {
      return this.property.formComponent == 'checkbox';
    },
    formGroupClassName() {
      return [
        'form-group',
        {
          'col-md-12': !this.property.parentClassName
        },
        this.property.parentClassName
      ]
    },
    formComponentLabelInner() {
      if (!this.isCheckbox) return;

      return this.property.label.title;
    },
    formComponentName() {
      if (!this.isCheckbox) return;
      if (this.property.customName) return this.customName;

      return this.name;
    },
    formComponentLabelOuter() {
      if (!this.property.label || this.isCheckbox) return;

      return this.property.label.title;
    },
    canBeInherited() {
      if (!this.property.label || this.property.label.canNotBeInherited) return;

      return true;
    },
    formComponentIsDisabled() {
      if (this.property.isDisabled) return true;

      return this.inheritedFields.includes(this.name) && (this.parentId != null || this.$route.query.copyFromId != null) && this.canBeInherited;
    }
  },
  methods: {
    handleInput(e) {
      const v = this.property.isNumber ? +this.content : this.content;

      this.$emit('input', v);
    }
  },
  mounted() {
    if (this.$parent.name == 'Partner' || this.$parent.partnerFormConfig) return this.property = this.$parent.getFormControlSettings(this.name);
  }
};
</script>
