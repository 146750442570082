import * as apolloQueries from '../partner/queries.js';
import errorHandler from '@/lib/ErrorHandler';
import partnerDefaultSettings from '@/assets/data/partner_default_settings.js';
import { uploadOptions } from '@/configs/partner_form';
import deepmerge from 'deepmerge';

import { diff } from 'deep-object-diff';

export const constants = {
  PARTNER_DEFAULT_FIELD_VALUES: {
    id: null,
    name: '',
    domains: '',
    claimSettings: {
      notificationEmails: '',
      notificationTelegramChatId: null,
      isNotificationTelegramChatIdRemove: false,
      isRequiredPhoneVerification: true
    },
    webAnalytics: [],
    mappingSettings: {
      cianJkIds: ''
    },
    webOptions: {
      meta: {
        url: '',
        robots: partnerDefaultSettings.meta.robots,
        type: partnerDefaultSettings.meta.type
      },
      logo: {
        link: '',
        marginBottom: null
      },
      bottomBarButtons: partnerDefaultSettings.bottomBarButtons,
      bottomBarButtonsType: partnerDefaultSettings.bottomBarButtonsType,
      ownTheme: null,
      headerBg: '',
      realisteLogo: 'dark',
      onboardingLinks: partnerDefaultSettings.onboardingLinks,
      canSwitchTheme: partnerDefaultSettings.canSwitchTheme,
      canSwitchLocale: partnerDefaultSettings.canSwitchLocale,
      hasOnboarding: partnerDefaultSettings.hasOnboarding,
      hasRent: true,
      showNotAvailableProdutsAsAvailableInPROVersion: false,
      useSiteV2IndexMapAnalgeo: false,
      useCryptoCurrencies: false,
      allowDesktop: false,
      isSiteWhiteLabelNoRealisteMentions: false,
      showRealisteCommitmentsOrCommissions: false,
      isSiteForBrokers: false,
      intercom: false,
      isVisibleMediaInLoader: partnerDefaultSettings.isVisibleMediaInLoader,
      selectedCity: partnerDefaultSettings.selectedCity,
      initialPage: partnerDefaultSettings.initialPage,
      privacyPolicy: '',
      customFooterHtml: '',
      countryCode: partnerDefaultSettings.countryCode,
      locale: partnerDefaultSettings.locale,
      availableCities: partnerDefaultSettings.availableCities,
      realisteLandingUrl: partnerDefaultSettings.realisteLandingUrl,
      telegramChannelUrl: partnerDefaultSettings.telegramChannelUrl,
      bugReport: {
        magicProUrl: partnerDefaultSettings.bugReport.magicProUrl,
        telegramGroupUrl: partnerDefaultSettings.bugReport.telegramGroupUrl
      },
      cenLat: null,
      cenLng: null,
      zoom: null,
      calcClaimPrepareResultTimeout: null,
      tradeUpExchangeOptionsAreVisible: true,
      canCloseSignUpForm: true,
      calcClaimResultSimplifiedView: false,
      propertyEstimationFormHasOnboarding: false,
      numberOfSecondsToDisplayAccessRequestModal: null,
      onboardingSlides: partnerDefaultSettings.onboardingSlides,
      claimPurpose: partnerDefaultSettings.claimPurpose,
      calcClaimPrepareResultAnimation: partnerDefaultSettings.calcClaimPrepareResultAnimation,
      calcClaimObjectTargetAction: partnerDefaultSettings.calcClaimObjectTargetAction,
      publicObjectTargetAction: partnerDefaultSettings.publicObjectTargetAction,
      tradeUpPublicObjectTargetAction: partnerDefaultSettings.tradeUpPublicObjectTargetAction,
      proPlanOffer: partnerDefaultSettings.proPlanOffer,
      disctrictInformationIsVisible: partnerDefaultSettings.disctrictInformationIsVisible,
      pricePurchaseIsVisible: partnerDefaultSettings.pricePurchaseIsVisible,
      dadataApiUrl: null,
      filtersSet: []
    }
  }
};

const transformStringToNestedObject = (string, v) => {
  let props = string.split('.');

  let last = props.pop();

  let ref = {};
  let object = ref = {};

  props.forEach(prop => {
    ref[prop] = {};
    ref = ref[prop];
  });

  ref[last] = v;

  return object;
}

export class PartnerDataMapper {
  #apolloClient = null;
  #callbackStatus = null;
  #partner = null;
  #webOptionFaviconUrl = undefined;
  #webOptionLogoUrl = undefined;
  #webOptionLogoBackgroundWhiteUrl = undefined;
  #webOptionMetaOgImageUrl = undefined;
  #uploadOptions = {};
  #parentId = null;
  #inheritedFields = [];
  #children = []

  constructor(args) {
    this.#apolloClient = args.apolloClient;
  }

  get partner() {
    return this.#partner;
  }

  set partner(obj) {
    this.#partner = obj;
  }

  get parentId() {
    return this.#parentId;
  }

  set parentId(obj) {
    this.#parentId = obj;
  }

  get inheritedFields() {
    return this.#inheritedFields;
  }

  set inheritedFields(obj) {
    this.#inheritedFields = obj;
  }

  get callbackStatus() {
    return this.#callbackStatus;
  }

  set callbackStatus(val) {
    this.#callbackStatus = val;
  }

  get uploadOptions() {
    return this.#uploadOptions;
  }
  
  set uploadOptions(v) {
    this.#uploadOptions = v;
  }

  get webOptionLogoUrl() {
    return this.#webOptionLogoUrl;
  }

  get webOptionLogoBackgroundWhiteUrl() {
    return this.#webOptionLogoBackgroundWhiteUrl;
  }

  get webOptionMetaOgImageUrl() {
    return this.#webOptionMetaOgImageUrl;
  }

  get webOptionFaviconUrl() {
    return this.#webOptionFaviconUrl;
  }

  mergeInheritedObject(current, updates)  {
    if (!current) return;
    for (let key of Object.keys(updates)) {
      if (!current.hasOwnProperty(key) || typeof updates[key] !== 'object') current[key] = updates[key];
      else this.mergeInheritedObject(current[key], updates[key]);
    }

    return current;
  }

  mergeObjects(current, updates) {
    if (!current) return;
    for (let key of Object.keys(current)) {
      if (!updates || !updates.hasOwnProperty(key)) continue;
      else if (typeof updates[key] == 'object' && !Array.isArray(updates[key])) this.mergeObjects(current[key], updates[key]);
      else {
        current[key] = updates[key]
      }
    }
    return current;
  }

  getChildUploadOptions(inheritedFields) {
    let res = {};

    const uploadOptionsKeys = Object.keys(uploadOptions);

    Object.entries(this.#uploadOptions).map(([key, value]) => {
      return Object.entries(uploadOptions).map(([propertyKey, propertyValue]) => {
        if ((propertyValue.gqlFieldName == key || propertyValue.gqlFieldNameIsRemove == key) && inheritedFields.includes(propertyKey) && uploadOptionsKeys.includes(propertyKey)) {
          res[key] = value;
          return;
        }

        return;
      });
    });

    return res;
  }

  async getPartner(id, copyFromId = false) {
    if (!id) {
      this.#partner = JSON.parse(JSON.stringify(constants.PARTNER_DEFAULT_FIELD_VALUES));

      return this.#partner;
    }
    const x = await this.#apolloClient.query({
      query: apolloQueries.query_partner,
      notifyOnNetworkStatusChange: true,  
      variables: {
        id
      },
    })
    .then((data, loading) => {
      if (!loading) {
        if (data == null || data.data.partner == null) {
          return errorHandler.handleError('Не удалось загрузить данные об объекте', id);
        }

        const id = !copyFromId ? data.data.partner.id : null;
        const name = !copyFromId ? data.data.partner.name : '';
        const domains = !copyFromId ? data.data.partner.domains : [];
        const webOptionMetaOgImageUrl = !copyFromId ? data.data.partner.webOptionMetaOgImageUrl : undefined;
        const webOptionFaviconUrl = !copyFromId ? data.data.partner.webOptionFaviconUrl : undefined
        const webOptionLogoUrl = !copyFromId ? data.data.partner.webOptionLogoUrl : undefined
        const webOptionLogoBackgroundWhiteUrl = !copyFromId ? data.data.partner.webOptionLogoBackgroundWhiteUrl : undefined

        this.#partner = this.changeToFormFormat({ ...data.data.partner, id, name, domains, webOptionMetaOgImageUrl, webOptionFaviconUrl, webOptionLogoUrl, webOptionLogoBackgroundWhiteUrl });
        this.#webOptionLogoUrl = this.#partner.webOptionLogoUrl;
        this.#webOptionFaviconUrl = this.#partner.webOptionFaviconUrl;
        this.#webOptionLogoBackgroundWhiteUrl = this.#partner.webOptionLogoBackgroundWhiteUrl;
        this.#webOptionMetaOgImageUrl = this.#partner.webOptionMetaOgImageUrl;
        this.#children = data.data.partner.children.map(el => this.changeToFormFormat(el));

        return this.#partner;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, id);
    });

    return x;
  }

  async changePartnerChild(childMainOptions, childUploadOptions) {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_partner_update,
      notifyOnNetworkStatusChange: true,
      variables: {
        ...this.changeToGraphqlFormat(childMainOptions, 'update'),
        ...childUploadOptions
      },
    })
    .then((data, loading) => {
      if (!loading) {
        const status = data.data.partnerUpdate.status;

        if (!status) {
          return errorHandler.handleError('Не удалось обновить данные для объекта', childMainOptions.id);
        }

        return status;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, `Object id: ${childMainOptions.id}`);
    });

    return x;
  }

  iterateChildren(children, parent) {
    if (children.length == 0) return true;
    let promiseArr = children.map(child => {
      const inheritedObject = child.inheritedFields
        .map((element) => {
          return transformStringToNestedObject(element);
        })
        .reduce((prev, curr) => {
          return this.mergeInheritedObject(prev, curr);
        }, {});

      const updatedCurrentObject = this.mergeObjects(inheritedObject, parent);

      const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

      const childMainOptions = deepmerge(child, updatedCurrentObject,  { arrayMerge: overwriteMerge });

      const childUploadOptions = this.getChildUploadOptions(child.inheritedFields);

      return this.changePartnerChild(childMainOptions, childUploadOptions)
        .then(status => {
          const id = child.id;
          this.#apolloClient.query({
            query: apolloQueries.query_partner,
            notifyOnNetworkStatusChange: true,  
            variables: {
              id
            },
          })
          .then((data) => {
            if (data.data.partner.children) {
              this.iterateChildren(data.data.partner.children.map(el => this.changeToFormFormat(el)), childMainOptions);
            }
          })
          return {
            childId: child.id,
            status
          };
        }).catch((error) => {
          return errorHandler.handleError(error, `Object id: ${childMainOptions.id}`);
        });
    });

    return Promise.all(promiseArr).then((status) => {
      return status;
    }).catch((err) => {
      return errorHandler.handleError(err);
    })
  }

  updateChildrenProperties() {
    this.iterateChildren(this.#children, this.#partner);
  }

  async getParentProperties(id) {
    const x = await this.#apolloClient.query({
      query: apolloQueries.query_partner_domains,
      notifyOnNetworkStatusChange: true,  
      variables: {
        id
      },
    })
    .then((data, loading) => {
      if (!loading) {
        if (data == null || data.data.partner == null) {
          return errorHandler.handleError('Не удалось загрузить данные об объекте', id);
        }

        return { domains: data.data.partner.domains, name: data.data.partner.name, id: data.data.partner.id };
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, id);
    });

    return x;
  }

  async partnerUpdate() {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_partner_update,
      notifyOnNetworkStatusChange: true,  
      variables: {
        ...this.changeToGraphqlFormat(this.#partner, 'update'),
        ...this.#uploadOptions,
        parent_id: +this.#parentId,
        inherited_fields: this.#inheritedFields
      },
    })
    .then((data, loading) => {
      if (!loading) {
        const status = data.data.partnerUpdate.status;

        if (!status) {
          return errorHandler.handleError('Не удалось обновить данные для объекта', this.changeToGraphqlFormat(this.#partner));
        }

        return status;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, this.changeToGraphqlFormat(this.#partner));
    });

    return x;
  }

  async partnerCreate() {
    const x = await this.#apolloClient.mutate({
      mutation: apolloQueries.mutation_partner_create,
      notifyOnNetworkStatusChange: true,  
      variables: {
        ...this.changeToGraphqlFormat(this.#partner, 'create'),
        ...this.#uploadOptions,
        parent_id: +this.#parentId,
        inherited_fields: this.#inheritedFields
      },
    })
    .then((data, loading) => {
      if (!loading) {
        const status = data.data.partnerCreate.status;

        if (!status) {
          return errorHandler.handleError('Не удалось создать нового партнера', this.changeToGraphqlFormat(this.#partner));
        }

        return status;
      }
    })
    .catch((error) => {
      return errorHandler.handleError(error, this.changeToGraphqlFormat(this.#partner));
    });

    return x;
  }

  changeToFormFormat(obj) {
    const emptyObject = constants.PARTNER_DEFAULT_FIELD_VALUES;

    const webOptions = {
      ...emptyObject.webOptions,
      ...obj.webOptions,
      customFooterHtml: obj.webOptions.customFooterHtml ? decodeURIComponent(obj.webOptions.customFooterHtml) : '',
      meta: {
        ...emptyObject.webOptions.meta,
        ...obj.webOptions.meta
      },
      bugReport: {
        ...emptyObject.webOptions.bugReport,
        ...obj.webOptions.bugReport
      }
    }

    const webAnalytics = obj.webAnalytics && obj.webAnalytics.length != 0 ? obj.webAnalytics.map(el => {
      return { ...el, options: el.options }
    }) : [];

    const res = Object.assign({}, obj, {
      domains: obj.domains ? obj.domains.join(', ') : '',
      claimSettings: {
        notificationEmails: obj.claimSettings.notificationEmails ? obj.claimSettings.notificationEmails.join(', ') : '',
        notificationTelegramChatId: obj.claimSettings.notificationTelegramChatId,
        isNotificationTelegramChatIdRemove: obj.claimSettings.isNotificationTelegramChatIdRemove,
        isRequiredPhoneVerification: obj.claimSettings.isRequiredPhoneVerification
      },
      mappingSettings: {
        cianJkIds: obj.mappingSettings.cianJkIds ? obj.mappingSettings.cianJkIds.join(', ') : '',
      },
      webOptions,
      webAnalytics
    });

    return res;
  }

  getOwnThemeColors(obj) {
    if (!obj) return;

    let res = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (!value) return;

      res[key] = value;
    });

    return res;
  }

  changeToGraphqlFormat(obj, type) {
    const webAnalytics = obj.webAnalytics && obj.webAnalytics.length != 0 ? obj.webAnalytics.map(el => {
      if (!el.type) return;
      return {
        id: +el.id,
        type: el.type,
        options: el.options,
        isDelete: el.isDelete || undefined
      }
    }).filter(el => el) : [];

    for (let el in obj.webOptions) {
      if (obj.webOptions[el] === 0) {
        obj.webOptions[el] = null
      }
    }

    obj.webOptions.customFooterHtml = obj.webOptions.customFooterHtml.replaceAll(/<script.*?>/gi, function(a){console.log(a);if(a.match(/[^a-z0-9](async|defer)[^a-z0-9]/)) return a; else return a.substring(0, a.length - 1) + ' async' + '>';})

    const wepOptionsDiff = diff(partnerDefaultSettings, {
      ...obj.webOptions,
      ownTheme: this.getOwnThemeColors(obj.webOptions.ownTheme)
    });

    const mappingSettings = obj.mappingSettings ? {
      cianJkIds: obj.mappingSettings.cianJkIds ? Array.from(obj.mappingSettings.cianJkIds.split(','), x => +x) : [],
    } : undefined;

    const res = {
      id: obj.id ? +obj.id : undefined,
      name: obj.name || undefined,
      domains: obj.domains ? obj.domains.split(',') : '',
      claim_settings: {
        notificationEmails: obj.claimSettings.notificationEmails ? obj.claimSettings.notificationEmails.split(',') : [],
        notificationTelegramChatId: obj.claimSettings.notificationTelegramChatId,
        isNotificationTelegramChatIdRemove: type == 'update' ? obj.claimSettings.isNotificationTelegramChatIdRemove : undefined,
        isRequiredPhoneVerification: obj.claimSettings.isRequiredPhoneVerification
      },
      mapping_settings: mappingSettings,
      web_options: {
        ...wepOptionsDiff,
        availableCities: JSON.stringify(partnerDefaultSettings.availableCities) == JSON.stringify(obj.webOptions.availableCities) ? undefined : obj.webOptions.availableCities,
        bottomBarButtons: JSON.stringify(partnerDefaultSettings.bottomBarButtons) == JSON.stringify(obj.webOptions.bottomBarButtons) ? undefined : obj.webOptions.bottomBarButtons,
        onboardingSlides: JSON.stringify(partnerDefaultSettings.onboardingSlides) == JSON.stringify(obj.webOptions.onboardingSlides) ? undefined : obj.webOptions.onboardingSlides,
        customFooterHtml: obj.webOptions.customFooterHtml ? encodeURIComponent(obj.webOptions.customFooterHtml) : undefined
      },
      web_analytics: webAnalytics
    };

    return res;
  }
}

export default PartnerDataMapper;
