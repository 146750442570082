import { PARTNER_CLAIM_PURPOSES, PARTNER_BOTTOM_BAR_BUTTONS, PARTNER_WEB_ANALYTIC_TYPES, PARTNER_REALISTE_LOGO_TYPES, PARTNER_TYPES, PARTNER_COLOR_THEMES } from '@/assets/data/constants';
import dataFactory from '@/services/DataFactory';

const claimPurposes = (scope) => {
  return PARTNER_CLAIM_PURPOSES.filter(el => el.scope.includes(scope));
}

const bottomBarButtons = () => {
  return Object.keys(PARTNER_BOTTOM_BAR_BUTTONS).map(key => {
    return { name: key, label: PARTNER_BOTTOM_BAR_BUTTONS[key] }
  });
}

const uploadOptions = {
  'webOptionMetaOgImageUrl': {
    formComponent: 'multiselect',
    gqlFieldName: 'web_option_meta_og_image',
    gqlFieldNameIsRemove: 'is_web_option_meta_og_image_remove',
    label: {
      canNotBeInherited: true
    }
  },
  'webOptionFaviconUrl': {
    formComponent: 'multiselect',
    gqlFieldName: 'web_option_favicon',
    gqlFieldNameIsRemove: 'is_web_option_favicon_remove',
    label: {
      canNotBeInherited: true
    }
  },
  'webOptionLogoUrl': {
    formComponent: 'multiselect',
    gqlFieldName: 'web_option_logo',
    gqlFieldNameIsRemove: 'is_web_option_logo_remove',
    label: {
      canNotBeInherited: true
    }
  },
  'webOptionLogoBackgroundWhiteUrl': {
    formComponent: 'multiselect',
    gqlFieldName: 'web_option_logo_background_white',
    gqlFieldNameIsRemove: 'is_web_option_logo_background_white_remove',
    label: {
      canNotBeInherited: true
    }
  },
}

async function partnerBuildConfig() {
  await dataFactory.getAvailableCities();

  return {
    properties: {
      id: {
        isDisabled: true,
        formComponent: 'input',
        label: {
          title: 'ID партнера *',
          canNotBeInherited: true
        },
        parentClassName: 'col-md-6'
      },
      name: {
        formComponent: 'input',
        label: {
          title: 'Имя партнера *',
          canNotBeInherited: true
        },
        parentClassName: 'col-md-6'
      },
      domains: {
        formComponent: 'textarea',
        label: {
          title: 'Список доменов <span class="text-muted small">(через запятую)</span> *',
          canNotBeInherited: true
        }
      },
      'claimSettings.isRequiredPhoneVerification': {
        formComponent: 'checkbox',
        label: {
          title: 'Требовать СМС подтверждение на ввод телефона'
        }
      },
      'webOptions.calcClaimPrepareResultTimeout': {
        formComponent: 'input',
        label: {
          title: 'Таймаут на просьбу ввести телефон в предпросмотре (кол-во секунд)'
        }
      },
      'webOptions.numberOfSecondsToDisplayAccessRequestModal': {
        formComponent: 'input',
        label: {
          title: 'Таймаут на просьбу ввести телефон на основной карте и карте с аналитикой (кол-во секунд)'
        }
      },
      'webOptions.tradeUpExchangeOptionsAreVisible': {
        formComponent: 'checkbox',
        label: {
          title: 'Отображать блок "Куда переехать" в трейдапе'
        }
      },
      'webOptions.canCloseSignUpForm': {
        formComponent: 'checkbox',
        label: {
          title: 'Иметь возможность закрыть форму ввода телефона'
        }
      },
      'webOptions.calcClaimResultSimplifiedView': {
        formComponent: 'checkbox',
        label: {
          title: 'Упрощенный вид результата заявки'
        }
      },
      'webOptions.calcClaimPrepareResultAnimation': {
        formComponent: 'checkbox',
        label: {
          title: 'Включить анимацию для предварительного результата заявки'
        }
      },
      'webOptions.disctrictInformationIsVisible': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать информацию о районе'
        }
      },
      'webOptions.pricePurchaseIsVisible': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать цену выкупа'
        }
      },
      'webOptions.publicObjectTargetAction': {
        formComponent: 'dropdown',
        label: {
          title: 'Целевое действие для объекта с карты'
        },
        options: claimPurposes('publicObjectTargetAction')
      },
      'webOptions.calcClaimObjectTargetAction': {
        formComponent: 'dropdown',
        label: {
          title: 'Целевое действие для объекта, который оценили'
        },
        options: claimPurposes('calcClaimObjectTargetAction')
      },
      'webOptions.tradeUpPublicObjectTargetAction': {
        formComponent: 'dropdown',
        label: {
          title: 'Целевое действие для объекта, на который можно поменять'
        },
        options: claimPurposes('tradeUpPublicObjectTargetAction')
      },
      'webOptions.proPlanOffer': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать кнопку с предложением перейти на PRO план на странице объекта'
        }
      },
      'claimSettings.notificationEmails': {
        formComponent: 'textarea',
        label: {
          title: 'Список емеилов для нотификаций <span class="text-muted small">(через запятую)</span>'
        }
      },
      'claimSettings.notificationTelegramChatId': {
        formComponent: 'input',
        label: {
          title: 'Идентификатор телеграм чата'
        }
      },
      'claimSettings.isNotificationTelegramChatIdRemove': {
        formComponent: 'checkbox',
        label: {
          title: 'Удалить идентификатор'
        }
      },
      'mappingSettings.cianJkIds': {
        formComponent: 'textarea',
        label: {
          title: 'ЖК Идентификторы <span class="text-muted small">(через запятую)</span>'
        }
      },
      'webOptions.customFooterHtml': {
        formComponent: 'textarea',
        label: {
          title: 'Кастомный HTML для футера'
        }
      },
      'counter.type': {
        formComponent: 'dropdown',
        label: {
          title: 'Тип счетчика *',
          canNotBeInherited: true
        },
        options: PARTNER_WEB_ANALYTIC_TYPES,
        parentClassName: 'col-md-6'
      },
      'counter.id': {
        formComponent: 'input',
        label: {
          title: 'ID счетчика *',
          canNotBeInherited: true
        },
        parentClassName: 'col-md-6'
      },
      'counter.options.clickmap': {
        formComponent: 'checkbox',
        label: {
          title: 'clickmap',
          canNotBeInherited: true
        },
        parentClassName: 'col',
        customName: true
      },
      'counter.options.trackLinks': {
        formComponent: 'checkbox',
        label: {
          title: 'trackLinks',
          canNotBeInherited: true
        },
        parentClassName: 'col',
        customName: true
      },
      'counter.options.accurateTrackBounce': {
        formComponent: 'checkbox',
        label: {
          title: 'accurateTrackBounce',
          canNotBeInherited: true
        },
        parentClassName: 'col',
        customName: true
      },
      'counter.options.webvisor': {
        formComponent: 'checkbox',
        label: {
          title: 'webvisor',
          canNotBeInherited: true
        },
        parentClassName: 'col',
        customName: true
      },
      'webOptions.meta.robots': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать страницу в результатах поиска'
        }
      },
      'webOptions.onboardingLinks': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать кнопки "Обучение" и "СМИ о Реалист" на карте'
        }
      },
      'webOptions.canSwitchTheme': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать переключатель между цветовыми схемами (светлая/темная)'
        }
      },

      'webOptions.canSwitchLocale': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать переключатель языка'
        }
      },
      'webOptions.hasOnboarding': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать онбординг (экран с инструкцией по использованию приложения)'
        }
      },
      'webOptions.propertyEstimationFormHasOnboarding': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать онбординг на форме ввода адреса'
        }
      },
      'webOptions.isVisibleMediaInLoader': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать СМИ во время расчета заявки'
        }
      },
      'webOptions.hasRent': {
        formComponent: 'checkbox',
        label: {
          title: 'Показывать расчет аренды если есть'
        }
      },
      'webOptions.showNotAvailableProdutsAsAvailableInPROVersion': {
        formComponent: 'checkbox',
        label: {
          title: 'Показать недоступные продукты как анонс PRO версии'
        }
      },
      'webOptions.useSiteV2IndexMapAnalgeo': {
        formComponent: 'checkbox',
        label: {
          title: 'Использовать новую карту индексов (SiteV2 Analgeo)'
        }
      },
      'webOptions.useCryptoCurrencies': {
        formComponent: 'checkbox',
        label: {
          title: 'Использовать криптовалюты'
        }
      },
      'webOptions.allowDesktop': {
        formComponent: 'checkbox',
        label: {
          title: 'Позволить включать десктоп версию'
        }
      },
      'webOptions.showRealisteCommitmentsOrCommissions': {
        formComponent: 'checkbox',
        label: {
          title: 'Отобразить обязательства Realiste (или комиссию брокерам)'
        }
      },
      'webOptions.isSiteWhiteLabelNoRealisteMentions': {
        formComponent: 'checkbox',
        label: {
          title: 'сайт White Label (без упоминания Реалиста)'
        }
      },
      'webOptions.isSiteForBrokers': {
        formComponent: 'checkbox',
        label: {
          title: 'Сайт для брокеров (предлагаем комиссию вместо гарантии/обязательств Реалист)'
        }
      },
      'webOptions.intercom': {
        formComponent: 'checkbox',
        label: {
          title: 'Включить Intercom'
        }
      },
      'webOptions.countryCode': {
        formComponent: 'dropdown',
        label: {
          title: 'Страна'
        },
        options: dataFactory.countryCodes,
        parentClassName: 'col-md-6'
      },
      'webOptions.locale': {
        formComponent: 'dropdown',
        label: {
          title: 'Выбранный язык'
        },
        options: [
          {
            name: 'ru',
            label: 'русский'
          },
          {
            name: 'en',
            label: 'английский'
          }
        ],
        parentClassName: 'col-md-6'
      },
      'webOptions.cenLat': {
        formComponent: 'input',
        isNumber: true,
        label: {
          title: 'Широта (lat)'
        },
        parentClassName: 'col-md-4'
      },
      'webOptions.cenLng': {
        formComponent: 'input',
        isNumber: true,
        label: {
          title: 'Долгота (lng)'
        },
        parentClassName: 'col-md-4'
      },
      'webOptions.zoom': {
        formComponent: 'input',
        isNumber: true,
        label: {
          title: 'Zoom'
        },
        parentClassName: 'col-md-4'
      },
      'webOptions.selectedCity': {
        formComponent: 'dropdown',
        label: {
          title: 'Выбранный город'
        },
        parentClassName: 'col-md-6',
        options: dataFactory.availableCitiesAsArray
      },
      'webOptions.initialPage': {
        formComponent: 'dropdown',
        label: {
          title: 'Выбранная страница'
        },
        parentClassName: 'col-md-6',
        options: bottomBarButtons()
      },
      'webOptions.headerBg': {
        formComponent: 'input',
        label: {
          title: 'Цвет шапки на форме заявки'
        },
        parentClassName: 'col-md-6',
        note: 'В формате <code>#f0f0f0</code>'
      },
      'webOptions.realisteLogo': {
        formComponent: 'dropdown',
        label: {
          title: 'Цвет лого Realiste на форме'
        },
        parentClassName: 'col-md-6',
        options: PARTNER_REALISTE_LOGO_TYPES
      },
      'webOptions.meta.type': {
        formComponent: 'dropdown',
        label: {
          title: 'Мета-теги'
        },
        options: PARTNER_TYPES
      },
      'webOptions.meta.htmlTitle': {
        formComponent: 'textarea',
        label: {
          title: 'Заголовок - htmlTitle'
        }
      },
      'webOptions.meta.title': {
        formComponent: 'textarea',
        label: {
          title: 'Заголовок'
        }
      },
      'webOptions.meta.description': {
        formComponent: 'textarea',
        label: {
          title: 'Описание'
        }
      },
      'webOptions.meta.url': {
        formComponent: 'input',
        label: {
          title: 'Url'
        }
      },
      'webOptions.logo.marginBottom': {
        formComponent: 'input',
        label: {
          title: 'Отступ снизу от лого'
        },
        note: 'Текст в формате <code>24</code>',
        parentClassName: 'col-md-4',
      },
      'webOptions.logo.link': {
        formComponent: 'input',
        label: {
          title: 'Ссылка на сайт партнера'
        },
        parentClassName: 'col-md-4',
      },
      'webOptions.bottomBarButtonsType': {
        formComponent: 'dropdown',
        label: {
          title: 'Список ссылок в нижнем меню'
        },
        options: PARTNER_TYPES
      },
      'webOptions.ownTheme.name': {
        formComponent: 'input',
        label: {
          title: 'Имя'
        },
        parentClassName: 'col-md-6',
        note: 'Чтобы добавить кастомную схему, обратитесь к администратору',
      },
      'webOptions.ownTheme.colorTheme': {
        formComponent: 'dropdown',
        label: {
          title: 'Цвет карты'
        },
        parentClassName: 'col-md-6',
        options: PARTNER_COLOR_THEMES
      },
      'webOptions.ownTheme.primaryColor': {
        formComponent: 'input',
        label: {
          title: 'Primary'
        },
        parentClassName: 'col-md-6',
        note: 'В формате <code>38, 196, 53</code>',
      },
      'webOptions.ownTheme.secondaryColor': {
        formComponent: 'input',
        label: {
          title: 'Secondary'
        },
        parentClassName: 'col-md-6',
        note: 'В формате <code>38, 196, 53</code>',
      },
      'webOptions.ownTheme.specialColor': {
        formComponent: 'input',
        label: {
          title: 'Special'
        },
        parentClassName: 'col-md-6',
        note: 'В формате <code>38, 196, 53</code>',
      },
      'webOptions.ownTheme.successColor': {
        formComponent: 'input',
        label: {
          title: 'Success'
        },
        parentClassName: 'col-md-6',
        note: 'В формате <code>38, 196, 53</code>',
      },
      'webOptions.realisteLandingUrl': {
        formComponent: 'input',
        label: {
          title: 'Realiste landing url'
        },
        parentClassName: 'col-md-6',
      },
      'webOptions.bugReport.magicProUrl': {
        formComponent: 'input',
        label: {
          title: 'PRO url'
        },
        parentClassName: 'col-md-6',
      },
      'webOptions.telegramChannelUrl': {
        formComponent: 'input',
        label: {
          title: 'Telegram канал'
        },
        parentClassName: 'col-md-6',
      },
      'webOptions.bugReport.telegramGroupUrl': {
        formComponent: 'input',
        label: {
          title: 'Telegram группа'
        },
        parentClassName: 'col-md-6',
      },
      'webOptions.availableCities': {
        formComponent: 'multiselect'
      },
      ...uploadOptions,
      'webOptions.onboardingSlides': {
        formComponent: 'multiselect'
      },
      'webOptions.privacyPolicy': {
        formComponent: 'textarea',
        label: {
          title: 'Политика конфиденциональности'
        }
      },
      'webAnalytics': {
        label: {
          title: 'Параметры веб-счетчиков'
        }
      },
      'filtersSet': {
        label: {
          title: 'Набор фильтров'
        }
      },
      'filtersSet.name': {
        formComponent: 'input',
        label: {
          title: 'Имя',
          canNotBeInherited: true
        }
      },
      'filtersSet.iconUrl': {
        formComponent: 'input',
        hasImgPreview: true,
        label: {
          title: 'Ссылка на иконку',
          canNotBeInherited: true
        }
      },
      'filtersSet.magicUrl': {
        formComponent: 'textarea',
        label: {
          title: 'Ссылка на magic',
          canNotBeInherited: true
        }
      },
    }
  }
}

export { partnerBuildConfig, uploadOptions };
