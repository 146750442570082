<template>
  <div class="col-md-12">
    <label class="w-100 text-h4 display-h4">
      <span>Набор фильтров</span>
      <inherit-val name="filtersSet"></inherit-val>
    </label>
    <pre>Cистемные фильтры:
    * best_for_investment_ - лучшее для инвистиций
    * best_for_rent_ - для аренды
    * cheapest_ - дешевые
    * most_prestigious_building_ - престижные здания
    </pre>
    <div v-for="(item, index) in value"
          v-bind:key="`webAnalytics_${index}`">
      <div v-bind:class="['form-row pt-2', { disabled: isDisabled }]">
        <form-property name="filtersSet.name" v-model="item.name"></form-property>
        <form-property name="filtersSet.iconUrl" v-model="item.iconUrl"></form-property>
        <form-property name="filtersSet.magicUrl" v-model="item.magicUrl"></form-property>
        <div class="form-group col-md-12">
          <button v-on:click.prevent="deleteSet(index)"
                  class="btn btn-sm btn-danger">Удалить</button>
        </div>
      </div>
    </div>
    <button v-on:click="addNewSet"
            class="btn btn-primary btn-sm"
            v-bind:disabled="isDisabled">Добавить фильтр</button>
  </div>
</template>

<script>
import FormProperty from '@/components/partners/FormProperty.vue';
import InheritVal from '@/components/partners/InheritVal.vue';
import { mapGetters } from 'vuex';

export default {
  props: ['value', 'partnerFormConfig'],

  data() {
    return {
      filtersSet: this.value,
      name: 'filtersSet'
    }
  },

  components: {
    FormProperty,
    InheritVal
  },

  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
      parentId: 'partners/getParentId'
    }),

    isDisabled() {
      return this.inheritedFields.includes(this.name) && (this.parentId != null || this.$route.query.copyFromId != null);
    }
  },

  methods: {
    addNewSet() {
      this.filtersSet.push({
        iconUrl: '',
        magicUrl: '',
        name: ''
      })
    },

    deleteSet(index) {
      const spliced = this.filtersSet.splice(index, 1);
    },

    getFormControlSettings(name) {
      if (!this.partnerFormConfig) return;
      
      return this.partnerFormConfig[Object.keys(this.partnerFormConfig).find(key => key == name)];
    }
  }
}
</script>
