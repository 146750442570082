<template>
  <div class="main">
    <data-loader v-bind:isLoading="!сontentIsVisible"
                 innerText="Подождите, данные загружаются"
                 v-bind:isSpinner="true" />
    <div class="container-fluid pt-4 pb-4"
         v-if="сontentIsVisible">
      <div class="card">
        <div class="card-body">
          <h1>{{ title }}</h1>
          <div class="form-row"
                v-if="queryStatus == 'default' && сontentIsVisible">
            <p class="text-muted col-md-12">
              Поля со звездочкой (*) обязательные
            </p>
            <div class="col-md-12 form-group" v-if="parentProperties || partner.children">
              <div class="card partner-parent-details">
                <div class="card-header">
                  Данные о родителе
                  <div class="card-info" @click="openModalAbout">?</div>
                </div>
                <ul class="list-group list-group-flush">
                  <li v-if="parentProperties && parentProperties.name" class="list-group-item">
                    <strong>Имя родителя:</strong> {{ parentProperties.name }}
                  </li>
                  <li class="list-group-item" v-if="parentProperties">
                    <strong>Ссылка на родителя:</strong> <a v-bind:href="`https://${parentProperties.domains[0]}`" target="_blank">{{ parentProperties.domains[0] }}</a>
                  </li>
                  <li v-if="childrenNames" class="list-group-item">
                    <strong>Имена детей:</strong> {{ childrenNames }}
                  </li>
                  <li class="list-group-item" v-if="childrenUrls">
                    <strong>Ссылки на детей:</strong>
                    <span class="ml-1" v-for="(url, index) in childrenUrls" v-bind:key="url">
                      <a v-bind:href="`https://${url}`" target="_blank">{{ url }}</a>
                      <span v-if="index != childrenUrls.length - 1">, </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-row">
                <h4 class="col-md-12">Основная информация</h4>

                <form-property name="id" v-if="partner.id" v-model="partner.id"></form-property>
                <form-property name="name" v-model="partner.name"></form-property>
                <form-property name="domains" v-model="partner.domains"></form-property>

                <h4 class="pt-2 col-md-12">Параметры заявки</h4>

                <form-property name="claimSettings.isRequiredPhoneVerification" v-model="partner.claimSettings.isRequiredPhoneVerification"></form-property>
                <form-property name="webOptions.calcClaimPrepareResultTimeout" v-model="partner.webOptions.calcClaimPrepareResultTimeout"></form-property>
                <form-property name="webOptions.numberOfSecondsToDisplayAccessRequestModal" v-model="partner.webOptions.numberOfSecondsToDisplayAccessRequestModal"></form-property>
                <form-property name="webOptions.tradeUpExchangeOptionsAreVisible" v-model="partner.webOptions.tradeUpExchangeOptionsAreVisible"></form-property>
                <form-property name="webOptions.canCloseSignUpForm" v-model="partner.webOptions.canCloseSignUpForm"></form-property>
                <form-property name="webOptions.calcClaimResultSimplifiedView" v-model="partner.webOptions.calcClaimResultSimplifiedView"></form-property>
                <form-property name="webOptions.calcClaimPrepareResultAnimation" v-model="partner.webOptions.calcClaimPrepareResultAnimation"></form-property>
                <form-property name="webOptions.disctrictInformationIsVisible" v-model="partner.webOptions.disctrictInformationIsVisible"></form-property>
                <form-property name="webOptions.pricePurchaseIsVisible" v-model="partner.webOptions.pricePurchaseIsVisible"></form-property>

                <h4 class="pt-2 col-md-12">Целевое действие</h4>

                <form-property name="webOptions.publicObjectTargetAction" v-model="partner.webOptions.publicObjectTargetAction"></form-property>
                <form-property name="webOptions.calcClaimObjectTargetAction" v-model="partner.webOptions.calcClaimObjectTargetAction"></form-property>
                <form-property name="webOptions.tradeUpPublicObjectTargetAction" v-model="partner.webOptions.tradeUpPublicObjectTargetAction"></form-property>
                <form-property name="webOptions.proPlanOffer" v-model="partner.webOptions.proPlanOffer"></form-property>
                <form-property name="claimSettings.notificationEmails" v-model="partner.claimSettings.notificationEmails"></form-property>
                <form-property name="claimSettings.notificationTelegramChatId" v-model="partner.claimSettings.notificationTelegramChatId"></form-property>
                <form-property name="claimSettings.isNotificationTelegramChatIdRemove" v-model="partner.claimSettings.isNotificationTelegramChatIdRemove"></form-property>

                <h4 class="pt-2 col-md-12">ЖК маппинг</h4>

                <form-property name="mappingSettings.cianJkIds" v-model="partner.mappingSettings.cianJkIds"></form-property>

                <h4 class="pt-2 mb-2 col-md-12">Кастомный HTML</h4>

                <form-property name="webOptions.customFooterHtml" v-model="partner.webOptions.customFooterHtml"></form-property>

                <web-analytics v-bind:webAnalytics="partner.webAnalytics"
                               v-bind:partnerFormConfig="partnerFormConfig"
                               ref="webAnalytics"
                               class="mb-3"></web-analytics>

                <filters-set ref="filtersSet"
                             v-model="partner.webOptions.filtersSet"
                             v-bind:partnerFormConfig="partnerFormConfig"></filters-set>

              </div>

            </div>
            <div class="col-md-6">
              <div class="form-row">
                <h4 class="col-md-12">Дополнительные параметры</h4>

                <form-property name="webOptions.meta.robots" v-model="partner.webOptions.meta.robots"></form-property>
                <form-property name="webOptions.onboardingLinks" v-model="partner.webOptions.onboardingLinks"></form-property>
                <form-property name="webOptions.canSwitchTheme" v-model="partner.webOptions.canSwitchTheme"></form-property>
                <form-property name="webOptions.canSwitchLocale" v-model="partner.webOptions.canSwitchLocale"></form-property>
                <form-property name="webOptions.propertyEstimationFormHasOnboarding" v-model="partner.webOptions.propertyEstimationFormHasOnboarding"></form-property>
                <form-property name="webOptions.isVisibleMediaInLoader" v-model="partner.webOptions.isVisibleMediaInLoader"></form-property>
                <form-property name="webOptions.hasRent" v-model="partner.webOptions.hasRent"></form-property>
                <form-property name="webOptions.showNotAvailableProdutsAsAvailableInPROVersion" v-model="partner.webOptions.showNotAvailableProdutsAsAvailableInPROVersion"></form-property>
                <form-property name="webOptions.useSiteV2IndexMapAnalgeo" v-model="partner.webOptions.useSiteV2IndexMapAnalgeo"></form-property>
                <form-property name="webOptions.useCryptoCurrencies" v-model="partner.webOptions.useCryptoCurrencies"></form-property>
                <form-property name="webOptions.allowDesktop" v-model="partner.webOptions.allowDesktop"></form-property>
                <form-property name="webOptions.isSiteWhiteLabelNoRealisteMentions" v-model="partner.webOptions.isSiteWhiteLabelNoRealisteMentions"></form-property>
                <form-property name="webOptions.showRealisteCommitmentsOrCommissions" v-model="partner.webOptions.showRealisteCommitmentsOrCommissions"></form-property>
                <form-property name="webOptions.isSiteForBrokers" v-model="partner.webOptions.isSiteForBrokers"></form-property>
                <form-property name="webOptions.intercom" v-model="partner.webOptions.intercom"></form-property>
                <form-property name="webOptions.countryCode" v-model="partner.webOptions.countryCode"></form-property>
                <form-property name="webOptions.locale" v-model="partner.webOptions.locale"></form-property>

                <div class="form-group col-md-12">
                  <label>Доступный список локаций <inherit-val name="webOptions.availableCities"></inherit-val></label>
                  <multi-select v-bind:value="getAvailableCities(partner.webOptions.availableCities)"
                                v-bind:options="availableCitiesValues"
                                v-bind:searchable="true"
                                v-on:input="setAvailableCities"
                                name="webOptions.availableCities"
                                trackBy="">
                    <span slot="noResult">Не найдено ни одной локации</span>
                  </multi-select>
                </div>

                <form-property name="webOptions.cenLat" v-model="partner.webOptions.cenLat"></form-property>
                <form-property name="webOptions.cenLng" v-model="partner.webOptions.cenLng"></form-property>
                <form-property name="webOptions.zoom" v-model="partner.webOptions.zoom"></form-property>

                <form-property name="webOptions.selectedCity" v-model="partner.webOptions.selectedCity"></form-property>
                <form-property name="webOptions.initialPage" v-model="partner.webOptions.initialPage"></form-property>

                <form-property name="webOptions.headerBg" v-model="partner.webOptions.headerBg"></form-property>
                <form-property name="webOptions.realisteLogo" v-model="partner.webOptions.realisteLogo"></form-property>

                <!-- <h5 class="mb-2 col-md-12">Мета-теги</h5> -->

                <form-property name="webOptions.meta.type" v-model="partner.webOptions.meta.type"></form-property>

                <template v-if="hasCustomMetaType">
                  <form-property name="webOptions.meta.title" v-model="partner.webOptions.meta.title"></form-property>
                  <form-property name="webOptions.meta.htmlTitle" v-model="partner.webOptions.meta.htmlTitle"></form-property>
                  <form-property name="webOptions.meta.description" v-model="partner.webOptions.meta.description"></form-property>
                  <form-property name="webOptions.meta.url" v-model="metaUrl"></form-property>
                </template>

                <div class="form-group col-md-6">
                  <h6 class="mb-0">OG изображение</h6>
                  <div class="text-muted small mb-3 pt-1">* Новое изображение перетирает предыдущее</div>
                  <upload-image fieldName="web_option_meta_og_image"
                                fieldUrl="webOptionMetaOgImageUrl"
                                fieldRemove="is_web_option_meta_og_image_remove"
                                v-bind:partnerInstance="partnerInstance"
                                class="pb-2"
                                name="webOptionMetaOgImageUrl"></upload-image>
                </div>
                <div class="form-group col-md-6">
                  <h6 class="mb-0">Фавикон</h6>
                  <div class="text-muted small mb-3 pt-1">* Новое изображение перетирает предыдущее</div>
                  <upload-image fieldName="web_option_favicon"
                                fieldUrl="webOptionFaviconUrl"
                                fieldRemove="is_web_option_favicon_remove"
                                v-bind:partnerInstance="partnerInstance"
                                class="pb-2"
                                name="webOptionFaviconUrl"></upload-image>
                </div>

                <h6 class="mb-0 col-md-12">Логотип партнера</h6>
                <div class="text-muted small mb-3 pt-1">* Новое изображение перетирает предыдущее</div>
                <div class="form-group col-md-12">
                  <upload-image fieldName="web_option_logo"
                                fieldUrl="webOptionLogoUrl"
                                fieldRemove="is_web_option_logo_remove"
                                v-bind:partnerInstance="partnerInstance"
                                name="webOptionLogoUrl"></upload-image>
                </div>
                <form-property name="webOptions.logo.marginBottom" v-model="partner.webOptions.logo.marginBottom"></form-property>
                <form-property name="webOptions.logo.link" v-model="partner.webOptions.logo.link"></form-property>

                <div class="col-md-12 form-group">
                  <h6 class="mb-0">Логотип партнера в модальном окне</h6>
                  <div class="text-muted small mb-3 pt-1">* Цветное лого на белом фоне. Не требует заполнения, если в модальном окне будет то же самое лого, что и на форме заявки и если шапка на форме светлая.</div>
                  <upload-image fieldName="web_option_logo_background_white"
                                fieldUrl="webOptionLogoBackgroundWhiteUrl"
                                fieldRemove="is_web_option_logo_background_white_remove"
                                v-bind:partnerInstance="partnerInstance"
                                class="pb-2"
                                name="webOptionLogoBackgroundWhiteUrl"></upload-image>
                </div>

                <div style="display: none;">{{ bottomBarButtonsList }}</div>
                <form-property name="webOptions.bottomBarButtonsType" v-model="partner.webOptions.bottomBarButtonsType"></form-property>
              </div>

              <div class="mb-2" v-if="hasBottomBarCustomValue">
                <inherit-val name="webOptions.bottomBarButtons"></inherit-val>
              </div>

              <multi-select v-bind:value="getBottomBarButtons(partner.webOptions.bottomBarButtons)"
                            v-bind:options="bottomBarButtons"
                            v-bind:searchable="true"
                            v-on:input="setBottomBarButtons"
                            name="webOptions.bottomBarButtons"
                            trackBy=""
                            v-if="hasBottomBarCustomValue">
                <span slot="noResult">Не найдено ни одного тега</span>
              </multi-select>
              <label class="pt-4">Список слайдов на онбординге <inherit-val name="webOptions.onboardingSlides"></inherit-val></label>
              <multi-select v-bind:value="getOnboardingSlides(partner.webOptions.onboardingSlides)"
                            v-bind:options="onboardingSlides"
                            v-bind:searchable="true"
                            v-on:input="setOnboardingSlides"
                            name="webOptions.onboardingSlides"
                            trackBy="">
                    <span slot="noResult">Не найдено ни одной слайда</span>
              </multi-select>
              <h4 class="pt-4">Собственная цветовая схема</h4>
              <div class="form-row"
                    v-if="partner.webOptions.ownTheme">
                <form-property name="webOptions.ownTheme.name" v-model="partner.webOptions.ownTheme.name"></form-property>
                <form-property name="webOptions.ownTheme.colorTheme" v-model="partner.webOptions.ownTheme.colorTheme"></form-property>

                <h5 class="pt-2 col-12 mb-1">Основные цвета</h5>
                <p class="text-muted small">
                  * primary - основной цвет, по дефолту - <span class="text-primary">зеленый</span>
                  <br>
                  * secondary - второстепенный, по дефолту - <span class="text-secondary">синий</span>. Необязательный к заполнению
                  <br>
                  * special - второй основной цвет, в этом цвете особенно важные объекты на сайте, по дефолту - <span class="text-special">фиолетовый</span>. Необязательный к заполнению
                  <br>
                  * success - этот текст указывает на успех, по дефолту - <span class="text-success">зеленый</span>. Необязательный к заполнению
                </p>
                <form-property name="webOptions.ownTheme.primaryColor" v-model="partner.webOptions.ownTheme.primaryColor"></form-property>
                <form-property name="webOptions.ownTheme.secondaryColor" v-model="partner.webOptions.ownTheme.secondaryColor"></form-property>
                <form-property name="webOptions.ownTheme.specialColor" v-model="partner.webOptions.ownTheme.specialColor"></form-property>
                <form-property name="webOptions.ownTheme.successColor" v-model="partner.webOptions.ownTheme.successColor"></form-property>
              </div>
              <button v-on:click="addOwnTheme"
                      class="btn btn-primary btn-sm mb-3"
                      v-if="!partner.webOptions.ownTheme">Добавить цветовую схему</button>
              <div class="form-row">
                <form-property name="webOptions.privacyPolicy" v-model="partner.webOptions.privacyPolicy"></form-property>

                <h4 class="col-md-12">Ссылки</h4>
                <form-property name="webOptions.realisteLandingUrl" v-model="partner.webOptions.realisteLandingUrl"></form-property>
                <form-property name="webOptions.bugReport.magicProUrl" v-model="partner.webOptions.bugReport.magicProUrl"></form-property>
                <form-property name="webOptions.telegramChannelUrl" v-model="partner.webOptions.telegramChannelUrl"></form-property>
                <form-property name="webOptions.bugReport.telegramGroupUrl" v-model="partner.webOptions.bugReport.telegramGroupUrl"></form-property>

              </div>
            </div>
          </div>
          <div v-if="queryStatus == 'success' && сontentIsVisible">
            <h4 class="text-center text-success mb-0">Изменения сохранены</h4>
          </div>
          <div v-if="queryStatus == 'error' && сontentIsVisible">
            <h4 class="text-center text-danger mb-0">Изменения не получилось сохранить. Попробуйте позже или обратитесь к администратору</h4>
          </div>
          <div class="form-row justify-content-center pb-4 pt-3">
            <button v-on:click.prevent="applyChanges"
                    type="button"
                    class="btn btn-primary btn-lg"
                    v-if="queryStatus == 'default'">Сохранить изменения</button>
          </div>
        </div>
      </div>
    </div>
    <modal v-bind:title="modalAbout.title"
           ref="modalFieldAbout">
      <template v-slot:modal-body>
        <div v-html="modalAbout.description"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import MultiSelect from '@/components/users/MultiSelect.vue';
import UploadImage from '@/components/partners/UploadImage.vue';
import { PartnerDataMapper } from '@/lib/data_mappers/partner/partner.js';
import { mapGetters } from 'vuex';
import { apolloClient } from '@/lib/apollo';
import DataLoader from '@/components/DataLoader.vue';
import FormProperty from '@/components/partners/FormProperty.vue';
import WebAnalytics from '@/components/partners/WebAnalytics.vue';
import FiltersSet from '@/components/partners/FiltersSet.vue';
import InheritVal from '@/components/partners/InheritVal.vue';
import { partnerBuildConfig } from '@/configs/partner_form';
import { PARTNER_BOTTOM_BAR_BUTTONS, PARTNER_ONBOARDING_SLIDES, PARTNER_INHERITE_RULES } from '@/assets/data/constants';
import dataFactory from '@/services/DataFactory';

export default {
  props: {
    partner_id: [Number, String]
  },
  components: {
    MultiSelect,
    UploadImage,
    DataLoader,
    FormProperty,
    WebAnalytics,
    InheritVal,
    FiltersSet,
    Modal: () => import('@/components/common/Modal.vue')
},
  data() {
    return {
      action: '',
      partner: null,
      сontentIsVisible: false,
      newCounter: null,
      newCounterIsAdded: true,
      partnerInstance: null,
      metaUrl: '',
      queryStatus: 'default',
      isRemove: null,
      hasCustomMetaType: null,
      hasBottomBarCustomValue: null,
      bottomBarButtonsList: [],
      parentProperties: [],
      partnerFormConfig: null,
      availableCities: null,
      name: 'Partner',
    };
  },
  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
    }),
    isPartnerCreate() {
      return this.action == 'create';
    },
    isPartnerUpdate() {
      return this.action == 'update';
    },
    title() {
      return this.isPartnerCreate ? 'Создать нового партнера' : 'Изменить данные о партнере';
    },
    bottomBarButtons() {
      return Object.values(PARTNER_BOTTOM_BAR_BUTTONS);
    },
    availableCitiesValues() {
      if (!this.availableCities) return;

      return Object.values(this.availableCities);
    },
    modalAbout() {
      return PARTNER_INHERITE_RULES;
    },
    onboardingSlides() {
      return Object.values(PARTNER_ONBOARDING_SLIDES);
    },
    onboardingSlidesDropdown() {
      return Object.keys(PARTNER_ONBOARDING_SLIDES).map(key => {
        return { name: key, label: PARTNER_ONBOARDING_SLIDES[key] }
      });
    },
    partnerProperties() {
      if (!this.partnerFormConfig) return;

      return Object.entries(this.partnerFormConfig).map(([key, value]) => {
        if (value.label && value.label.canNotBeInherited) return;

        return key;
      }).filter(el => el);
    },
    childrenIds() {
      if (!this.partner || this.partner.children.length == 0) return;

      return Array.from(this.partner.children, x => x.id);
    },
    childrenNames() {
      if (!this.partner || this.partner.children.length == 0) return;

      return Array.from(this.partner.children, x => x.name).join(', ');
    },
    childrenUrls() {
      if (!this.partner || this.partner.children.length == 0) return;

      return Array.from(this.partner.children, x => x.domains[0]);
    }
  },
  methods: {
    updateState(payload) {
      this.isLoading = payload.isLoading;
    },
    init(args) {
      const partnerId = this.partner_id || this.$route.query.copyFromId;

      this.partnerInstance = new PartnerDataMapper({ apolloClient, partnerId });
      this.partnerInstance.callbackStatus = this.updateState.bind(this);
      this.action = args.action;
      this.сontentIsVisible = false;
      this.queryStatus = 'default';

      const copyFromId = this.$route.query.copyFromId != null;

      this.partnerInstance.getPartner(partnerId, copyFromId).then(res => {
        if (res) {
          this.сontentIsVisible = true;
          this.partner = JSON.parse(JSON.stringify(res));
          this.hasCustomMetaType = this.partner.webOptions.meta.type == 'custom_value';
          this.hasBottomBarCustomValue = this.partner.webOptions.bottomBarButtonsType == 'custom_value';
          this.metaUrl = this.partner.domains ? `https://${this.partner.domains.split(',')[0]}/` : '';
          this.parentProperties = this.partner.parentProperties;
          if (this.partner.inheritedFields.length != 0) {
            this.$store.commit('partners/updateInheritedFields', this.partner.inheritedFields);
          } else {
            this.$store.commit('partners/updateInheritedFields', this.partnerProperties);
          }

          if (this.partner.parentId) {
            this.$store.commit('partners/setParentId', this.partner.parentId);
            this.getParentProperties(this.partner.parentId);
          }
        }
      });
    },
    getParentProperties() {
      this.partnerInstance.getParentProperties(this.partner.parentId).then(res => {
        if (res) {
          this.parentProperties = res;
        }
      });
    },
    addOwnTheme() {
      this.partner.webOptions.ownTheme = {
        name: '',
        colorTheme: ''
      }
    },
    getBottomBarButtons(res) {
      if (!res) return [];
      return Array.from(res, x => PARTNER_BOTTOM_BAR_BUTTONS[x]);
    },
    setBottomBarButtons(res) {
      this.partner.webOptions.bottomBarButtons = Array.from(res, x => Object.keys(PARTNER_BOTTOM_BAR_BUTTONS).find(key => PARTNER_BOTTOM_BAR_BUTTONS[key] === x));
      this.bottomBarButtonsList = this.partner.webOptions.bottomBarButtons;
    },
    getAvailableCities(res) {
      if (res.length == 0 || !this.availableCities) return;
      return Array.from(res, x => this.availableCities[x]);
    },
    setAvailableCities(res) {
      if (res.length == 0 || !this.availableCities) return;
      this.partner.webOptions.availableCities = Array.from(res, x => Object.keys(this.availableCities).find(key => this.availableCities[key] == x));
    },
    getOnboardingSlides(res) {
      if (res.length == 0) return;
      return Array.from(res, x => PARTNER_ONBOARDING_SLIDES[x]);
    },
    setOnboardingSlides(res) {
      if (res.length == 0) return;
      this.partner.webOptions.onboardingSlides = Array.from(res, x => Object.keys(PARTNER_ONBOARDING_SLIDES).find(key => PARTNER_ONBOARDING_SLIDES[key] == x));
    },
    applyChanges() {
      this.partnerInstance.partner = { ...this.partner, webAnalytics: this.$refs.webAnalytics.webAnalyticsArr };
      this.partnerInstance.parentId = this.$route.query.copyFromId || undefined;
      this.partnerInstance.inheritedFields = this.inheritedFields;

      this.сontentIsVisible = false;

      if (this.isPartnerUpdate) {
        const updateChildrenProperties = this.partnerInstance.updateChildrenProperties();
        const partnerUpdate = this.partnerInstance.partnerUpdate();

        Promise.all([updateChildrenProperties, partnerUpdate]).then(values => {
          this.сontentIsVisible = true;

          if ((Array.isArray(values[0]) && values[0].some(el => !el.status)) || !values[1] || values[1].error) {
            this.queryStatus = 'error';
            return;
          }

          this.queryStatus = 'success';
        });

        return;
      }

      if (this.isPartnerCreate) {
        this.partnerInstance.partnerCreate().then(status => {
          this.сontentIsVisible = true;
          this.queryStatus = !status || status.error ? 'error' : 'success';
        });

        return;
      }
    },
    getFormControlSettings(name) {
      if (!this.partnerFormConfig) return;

      return this.partnerFormConfig[Object.keys(this.partnerFormConfig).find(key => key == name)];
    },
    openModalAbout() {
      this.$refs.modalFieldAbout.openModal();
    },
    closeModalAbout() {
      this.$refs.modalFieldAbout.closeModal();
    }
  },
  mounted() {
    partnerBuildConfig().then(response => {
      if (!response) return;

      this.partnerFormConfig = response.properties;

      this.$nextTick(() => {
        if (this.partner_id) {
          this.init({ action: 'update' })
        } else {
          this.init({ action: 'create' })
        }
      });
    });

    dataFactory.getAvailableCities().then(response => {
      if (!response) return;

      this.availableCities = dataFactory.availableCities;
    });
  },
  watch: {
    metaUrl(val) {
      this.partner.webOptions.meta.url = val;
    },
    'partner.webOptions.meta.type'(val) {
      this.hasCustomMetaType = val == 'custom_value';
    },
    'partner.webOptions.bottomBarButtonsType'(val) {
      this.hasBottomBarCustomValue = val == 'custom_value';
    },
  },
}
</script>
