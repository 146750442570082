import axios from 'axios';
import errorHandler from '@/lib/ErrorHandler';

const baseUrl = process.env.VUE_APP_EXTERNAL_DATA_STATIC_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

/* eslint-disable */
class DataFactory {
  #availableCities = null;
  #countryCodes = null;
  #availableCitiesAsArray = null;

  get availableCities() {
    return this.#availableCities;
  }

  get countryCodes() {
    return this.#countryCodes;
  }

  get availableCitiesAsArray() {
    return this.#availableCitiesAsArray;
  }

  showError(fileName, error) {
    errorHandler.handleError(`Cannot get access to ${baseUrl}/${fileName}`, { error });
  }

  async getAvailableCities() {
    return apiClient.get('/available_cities_public.json')
      .then(response => {
        this.#availableCities = Array.from(response.data, x => {
          let res = {};

          res[x.code] = x.name.ru;
          return res;
        }).reduce((result, item) => {
          var key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        }, {});

        this.#availableCitiesAsArray = Object.keys(this.#availableCities).map(key => {
          return { name: key, label: this.#availableCities[key] }
        });

        this.#countryCodes = Array.from(response.data, x => {
          return {
            name: x.countryCode,
            label: x.countryRuName
          }
        })
        .reduce((result, item) => {
          return result.filter(el => el.name == item.name).length != 0 ? result : [ ...result, item];
        }, []);

        return this.#availableCities;
      })
      .catch(error => {
        return this.showError('available_cities_public.json', error);
      });
  }
}

const dataFactory = new DataFactory();

export default dataFactory;
