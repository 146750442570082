<template>
  <div class="col-md-12">
    <label class="w-100 text-h4 display-h4">
      <span>Параметры веб-счетчиков</span>
      <inherit-val v-bind:name="name"></inherit-val>
    </label>
    <div v-for="(counter, index) in webAnalyticsArr"
          v-bind:key="`webAnalytics_${index}`">
      <div v-bind:class="['form-row pt-2', { disabled: isDisabled }]"
           v-if="!counter.isDelete">
        <form-property name="counter.type" v-model="counter.type" v-on:input="changeCounterType($event, index)"></form-property>
        <form-property name="counter.id" v-model="counter.id"></form-property>
        <div class="form-group col-md-12" v-if="counter.type == 'yandex_metrika'">
          <label>Дополнительные св-ва</label>
          <p class="text-muted small">* webvisor может иметь только один счетчик</p>
          <div class="checkbox-list form-row">
            <form-property name="counter.options.clickmap" v-model="counter.options.clickmap" v-bind:customName="`clickmap_${index}`"></form-property>
            <form-property name="counter.options.trackLinks" v-model="counter.options.trackLinks" v-bind:customName="`trackLinks_${index}`"></form-property>
            <form-property name="counter.options.accurateTrackBounce" v-model="counter.options.accurateTrackBounce" v-bind:customName="`accurateTrackBounce_${index}`"></form-property>
            <form-property name="counter.options.webvisor" v-model="counter.options.webvisor" v-bind:customName="`webvisor_${index}`"></form-property>
          </div>
        </div>
        <div class="form-group col-md-12">
          <button v-on:click.prevent=deleteCounter(index)
                  class="btn btn-sm btn-danger">Удалить</button>
        </div>
      </div>
    </div>
    <button v-on:click="addNewCounter"
            class="btn btn-primary btn-sm"
            v-bind:disabled="isDisabled">Добавить счетчик</button>
  </div>
</template>

<script>
import FormProperty from '@/components/partners/FormProperty.vue';
import InheritVal from '@/components/partners/InheritVal.vue';
import { mapGetters } from 'vuex';

export default {
  props: ['webAnalytics', 'partnerFormConfig'],

  data() {
    return {
      webAnalyticsArr: this.webAnalytics,
      name: 'webAnalytics'
    }
  },

  components: {
    FormProperty,
    InheritVal
  },

  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
      parentId: 'partners/getParentId'
    }),

    isDisabled() {
      return this.inheritedFields.includes(this.name) && (this.parentId != null || this.$route.query.copyFromId != null);
    }
  },

  methods: {
    addNewCounter() {
      this.webAnalyticsArr.push({
        id: null,
        type: '',
        options: {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        },
        isDelete: false
      })
    },

    deleteCounter(index) {
      const res = Array.from(this.webAnalyticsArr, (x, i) => {
        if (i == index) return { ...x, isDelete: true };
        return x;
      });

      this.webAnalyticsArr = res;
    },

    changeCounterType(e, index) {
      if (e == 'yandex_metrika') {
        this.webAnalyticsArr[index].options = {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        };

        return;
      }

      this.webAnalyticsArr[index].options = {};
    },

    getFormControlSettings(name) {
      if (!this.partnerFormConfig) return;
      
      return this.partnerFormConfig[Object.keys(this.partnerFormConfig).find(key => key == name)];
    }
  }
}
</script>
