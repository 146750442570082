export default {
  countryCode: 'ru',
  locale: 'ru',
  selectedCity: 'Москва',
  realisteLogo: 'dark',
  onboardingLinks: false,
  canSwitchTheme: false,
  canSwitchLocale: false,
  hasOnboarding: true,
  isVisibleMediaInLoader: true,
  realisteLandingUrl: 'https://realiste.ai/service_brokeridge',
  telegramChannelUrl: 'https://t.me/Realisteofficial',
  bugReport: {
    magicProUrl: 'https://realiste.ai/magic-pro-enter',
    telegramGroupUrl: 'https://t.me/joinchat/D9E-5xbj0w0nUgieILwaQg'
  },
  initialPage: 'tradeUp',
  meta: {
    robots: false,
    type: 'for_developer'
  },
  metaDefault: {
    for_bank: {
      title: 'Отобранные квартиры по ценам ниже рыночных и предварительный расчет кредита online',
      description: 'Заполните краткую форму и за 10 секунд узнайте результат предварительной оценки'
    },
    for_developer: {
      title: 'Автоматический подбор квартиры по Trade In',
      description: 'Узнайте за 10 секунд, на какие квартиры вы сможете обменять свою старую квартиру без доплаты. Сэкономьте в среднем 550 тыс. рублей, когда вы продаете старую квартиру, чтобы купить новую'
    }
  },
  ownTheme: {
    primaryColor: '38, 196, 53',
    specialColor: '128, 0, 128',
    successColor: '38, 196, 53',
    secondaryColor: '43, 135, 219'
  },
  availableCities: ['Москва', 'Московская область', 'Санкт-Петербург'],
  bottomBarButtonsType: 'for_developer',
  bottomBarButtonsDefault: {
    for_bank: ['map', 'propertyValuation', 'tradeUp'],
    for_developer: ['propertyValuation', 'tradeUp']
  },
  onboardingSlides: ['propertyValuation', 'poi', 'competitors', 'tradeUp'],
  claimPurpose: 'trade_up_map',
  calcClaimPrepareResultAnimation: true,
  calcClaimObjectTargetAction: 'trade_up_map',
  publicObjectTargetAction: 'wanna_view_only_phone',
  tradeUpPublicObjectTargetAction: 'trade_in',
  proPlanOffer: false,
  disctrictInformationIsVisible: true,
  pricePurchaseIsVisible: true
}
