<template>
  <div>
    <input v-bind:id="fieldName"
           type="file"
           v-on:change="onChange">
    <div class="form-row align-items-end pt-2"
          v-if="url">
      <div class="col-md-auto form-group">
        <img v-bind:src="url"
              alt="Image desctiption"
              class="partner-img">
      </div>
      <div class="col-md-auto form-group">
        <button class="btn btn-sm btn-danger"
                v-on:click.prevent="removeImage">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    fieldName: String,
    fieldUrl: String,
    fieldRemove: String,
    partnerInstance: Object,
    name: String
  },
  data() {
    return {
      url: null
    };
  },
  computed: {
    ...mapGetters({
      inheritedFields: 'partners/getInheritedFields',
      parentId: 'partners/getParentId'
    })
  },
  methods: {
    onChange({ target: { validity, files: [file], dataset } }) {
      if (!validity.valid) return;

      console.log('this.partnerInstance');
      console.log(this.partnerInstance);

      this.partnerInstance.uploadOptions[this.fieldName] = file;
      this.partnerInstance.uploadOptions[this.fieldRemove] = false;

      this.url = URL.createObjectURL(file);
    },

    removeImage({ target: { dataset } }) {
      console.log(dataset);
      this.partnerInstance.uploadOptions[this.fieldRemove] = true;
      this.url = null;
    }
  },
  mounted() {
    this.url = this.partnerInstance[this.fieldUrl];
  }
}
</script>
